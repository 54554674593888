import { API } from "./config";

// API's for Contact Us Form
export const CONTACT_US = `${API}/inquiry/create`;

//Contact us Contact Designation field get
export const GET_DESIGNATION = `${API}/contactdesignation/select1`;

//Contact us Business Domain field get
export const GET_BUSINESS_DOMAIN = `${API}/custbusidomain/select1`;

//Contact us Departments field get
export const GET_DEPARTMENTS = `${API}/custbusinessunit/select1`;

//  Careers
export const GET_CAREERS = `${API}/careers`;

export const SEARCH_CAREERS = `${API}/careers/search`;

export const APPLY_CAREERS = `${API}/apply/upload`;
