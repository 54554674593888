// PiE node api
export const API = "https://serv.pionglobal.com";

// company home page
export const HOME = "https://pionglobal.com";

/*
This is for PiE PORTAL variable is for giving link to the home page login button
*/
export const PORTAL = "https://portal.pionglobal.com";

// Products links
// Smart Hire url
export const SMART_HIRE_URL = "http://www.growthgamut.com";

// TechTran url
export const PIEDAP_URL = "https://www.piedap.io/";
