import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";

// import images
import icon1 from "../../../assets/img/service-icon1.png";
import icon2 from "../../../assets/img/service-icon2.png";
import icon3 from "../../../assets/img/service-icon3.png";

// import video
import video from "../../../assets/img/PION GLOBAL - Company Profile V1.0.mp4";

import HomeCard from "../../common/HomeCard";

const About = () => {
  // initiating the aos animation
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div id="about" data-aos="fade-right">
      <h4 className="dark-blue-text bold">About Us</h4>
      <Row className="m-0">
        <Col md="7" className="about-text-container pl-0">
          <h5>
            Pion Global Private Limited is a Techno Business Consulting company
            driven by innovative products, solutions, strategic partnerships,
            efficient delivery teams and aggressive market penetration.
          </h5>
          <h5>
            Our products team focus is to provide cost-effective technical and
            business solutions, with security, ease of use, agility, and
            quality.
          </h5>
          <h5>
            Our services team focuses on technologies, business challenges,
            custom applications, applications development and maintenance,
            testing, SaaS products, automation and professional services.
          </h5>
        </Col>
        <Col md="5" className="about-img-container pr-0">
          <div className="container-demo">
            <video controls="true" height="100%" width="100%">
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </Col>
      </Row>
      <Row className="about-grid-container-2 m-0">
        <Col sm="12" md="4" data-aos="fade-up" className="grid-first-col">
          <HomeCard
            icon={icon1}
            alt="first icon"
            heading="Vision"
            serviceIcon={true}
            visibleData="Provide efficient and controlled technical"
            data=" and business solutions to our customers with agility and faster time to market."
          />
        </Col>
        <Col sm="12" md="4" data-aos="fade-up" className="grid-second-col">
          <HomeCard
            icon={icon2}
            alt="second icon"
            heading="Mission"
            serviceIcon={true}
            visibleData="Drive innovations through next-generation"
            data=" digital transformation solutions and generate sustainable value for our customers."
          />
        </Col>
        <Col sm="12" md="4" data-aos="fade-up" className="grid-third-col">
          {" "}
          <HomeCard
            icon={icon3}
            alt="third icon"
            heading="Values"
            serviceIcon={true}
            visibleData="Our values focus on Trust, Transparency,"
            data=" Competence, Discipline, Customer Success, and Integrity above everything else."
          />
        </Col>
      </Row>
    </div>
  );
};
export default About;
