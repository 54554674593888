import React,{Component} from "react"
import axios from 'axios'
import { GET_CAREERS } from '../../../utils/routes'
import Slider from './Slider'

class Newsticker extends Component {
  constructor(){
    super();
    this.state = {
      items: []
    }
  }

  // Get the job list data
  componentDidMount() {
    const data = {domain:['Technology', 'Consulting', 'Sales', 'Support']};
        axios.post(
            GET_CAREERS,
            data,
            {withCredentials: true}
          )
          .then(res => {
            this.setState({
              items: res.data
            })
          })
  }
  
  render() {
     const {items} = this.state
    let items1 = []

    // The  items to be displayed in the dynamic moving list on the home page

    for(let i = 0; i < items.length; i++) {
      var x = {}
      x['name'] = items[i].jobRole
      x['id'] = items[i].jobCode
      items1.push(x)
    }

    // Send the items to be displayed in dynamic moving list to the Slider component
  return (
    <div className="Newsticker">
    {items1.length ?  <Slider items={ items1 } setSearch={this.props.setSearch} /> : null }
    </div>
  );
}
}

export default Newsticker;