import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <footer id="footer" className="flex">
      <Row className="m-0">
        <Col md="3" className="left"></Col>
        <Col md="6" className="center">
          <Link to="/privacy">
            <h6 style={{ color: "#f0f0f0", display: "inline" }}>Privacy </h6> |
          </Link>
          <Link to="/termsofuse">
            <h6 style={{ color: "#f0f0f0", display: "inline" }}>
              {" "}
              Terms of Use{" "}
            </h6>{" "}
            |
          </Link>
          <a href="#contact">
            <h6 style={{ color: "#f0f0f0", display: "inline" }}> Locations</h6>
          </a>
          <h6 className="footer-copyright">
            Copyright 2021 &copy; Pion Global Pvt. Ltd. All Rights Reserved.
          </h6>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
