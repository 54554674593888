import React from "react";
import img1 from "../../../assets/img/PionInnovation.jpg";
import img2 from "../../../assets/img/carousel-image-two.png";
import img3 from "../../../assets/img/carousel-image-three.png";

export const carouselData = [
  {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Innovation",
    headline: "Digital Experience Powered by Innovation and Agility",
    description: (
      <>
        <p>
          {" "}
          Our industry experts identifies the complex business challenges and
          build cost effective solutions with ease of use.
        </p>{" "}
        <p>
          {" "}
          We focus on new innovations in the areas of Digital, SaaS Offerings,
          Automation, AI and Custom Business Applications.
        </p>
      </>
    ),
    buttonLabel: "Learn More",
    imgStart: "",
    img: img1,
    alt: "Credit Card",
    to: "/#products",
  },
  {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Products and Solutions",
    headline: "Business Realizations and Transformations",
    description: (
      <>
        <p>
          We build, implement, and maintain SaaS Products and Solutions to
          address the challenges of Small, Medium and Large Enterprise
          Businesses.
        </p>
        <p>
          Our current SaaS solutions address the business needs of Talents
          Hiring, Enterprise Automation and Technology Transformations.{" "}
        </p>
      </>
    ),
    buttonLabel: "Learn More",
    imgStart: "",
    img: img2,
    alt: "Vault",
    to: "/#products",
  },
  {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Professional Services ",
    headline: "High Quality Professional Services at Speed",
    description: (
      <>
        <p>
          Our professional services team is comprised of highly skilled and
          dynamic talents who can provide superfast services to the customers.
        </p>
        <p>
          The team aligned with market trends, technology expertise, products
          implementations, and quality engineering.
        </p>
      </>
    ),
    buttonLabel: "Learn More",
    imgStart: "",
    img: img3,
    alt: "Vault",
    to: "/#services",
  },
];
