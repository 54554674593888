import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import img1 from "../../../assets/img/product-Overview-icon1.png";
import img3 from "../../../assets/img/piedaplogo.png";
import img4 from "../../../assets/img/product-Overview-icon4.png";
import { SMART_HIRE_URL } from "../../../utils/config";
import { PORTAL } from "../../../utils/config";
import { PIEDAP_URL } from "../../../utils/config";
import HomeCard from "../../common/HomeCard";

export default () => {
  // initiating the aos animation
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div id="products" data-aos="fade-right">
      <div className="left section-header prod-head">
        <h4 className="dark-blue-text bold service-header">Products</h4>
      </div>
      <Row className="prod-row m-0">
        <Col md="4" className="pl-0 prod-first-col" data-aos="fade-up">
          <HomeCard
            icon={img4}
            alt="Inteli Hire Icon"
            heading="Smart Hire"
            visibleData="Accelerate and Optimize your hiring"
            data=" solutions by leveraging
                  Digital Technology & Artificial Intelligence."
            href={SMART_HIRE_URL}
            productCard={true}
          />
        </Col>
        <Col md="4" className="prod-second-col" data-aos="fade-up">
          <HomeCard
            icon={img1}
            alt="Smart IT Icon"
            heading={
              <div id="employee-login-h1">
                <span style={{ color: "blue", fontWeight: "bold" }}>P</span>
                <span style={{ color: "red", fontWeight: "bold" }}>i</span>
                <span style={{ color: "green", fontWeight: "bold" }}>E</span>
              </div>
            }
            visibleData="An Intelligent Cloud Platform to run and"
            data=" manage your business
                  with Digital Experience."
            href={PORTAL}
            productCard={true}
          />
        </Col>
        <Col md="4" className="pr-0 prod-third-col" data-aos="fade-up">
          <HomeCard
            icon={img3}
            alt="Vani Icon"
            heading="PIEDAP"
            visibleData="PIEDAP is very modular in design. "
            data="At a functional level there are modules that work independently to provide point solution, as well as in integrated mode to provide the comprehensive service."
            href={PIEDAP_URL}
            productCard={true}
          />
        </Col>
      </Row>
    </div>
  );
};
