import React from "react";

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.renderItems = this.renderItems.bind(this);
  }

  renderItems({ items }) {
    return items.map((item) => {
      return (
        <div
          className="slide-item"
          onClick={(e) => this.props.setSearch(item.name)}
          key={item.id}
        >
          <h6 className="mb-0">{item.name}</h6>
        </div>
      );
    });
  }

  componentDidMount() {
    // Dynamic moving list component
    const height = this.ref.current ? this.ref.current.clientHeight : 200;
    const style = document.createElement("style");
    style.type = "text/css";

    // 0% value for starting of animation and 100% value for ending of animation
    const animation = `
    @keyframes slide {
      0% {
        bottom: -${height}px;
      }
      100% {
        bottom: 100%;
      }
    }`;
    style.innerHTML = animation;
    document.head.append(style);
  }

  // Render for dynamic moving list
  render() {
    return (
      <div className="slider">
        <div className="slide-track-container">
          <div ref={this.ref} className="slide-track">
            {this.renderItems(this.props)}
          </div>
        </div>
      </div>
    );
  }
}

export default Slider;
