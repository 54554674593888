import React, { Component } from "react";
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
} from "reactstrap";
import { APPLY_CAREERS } from "../../../utils/routes";
import Notifier from "../../aside/Notifier";
import ErrorNotifier from "../../aside/ErrorNotifier";
import {
  Validator,
  submitValidator,
  valid,
  errors,
  refresh,
} from "../../common/Validator";

class CareerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      resume: "",
      modal: false,
      fileError: "",
      message: "",
      error: "",
      formErrors: {
        name: "",
        email: "",
      },
    };
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onFileChange(e) {
    Validator(e.target);
    let allowedExtension = /(\.pdf|\.docx)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      this.setState({
        resume: e.target.files[0] || [],
        fileError: "",
      });
    } else {
      this.setState({
        resume: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  }

  // Reset function for resetting the state values after submit
  reset = () => {
    this.setState({
      name: "",
      email: "",
      phone: "",
      resume: "",
      modal: false,
      fileError: "",
      message: "",
      error: "",
      formErrors: {
        name: "",
        email: "",
      },
    });
  };

  /*
This function for the submitting the uploading the resume with candidate details
*/
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { fileError } = this.state;
    if (valid && !fileError) {
      let file = this.state.resume;

      let fd = new FormData();
      fd.append("jobId", this.props.job.jobCode);
      fd.append("candName", this.state.name);
      fd.append("email", this.state.email);
      fd.append("phone", this.state.phone);
      fd.append("resume", file);

      axios
        .post(APPLY_CAREERS, fd)
        .then((res) => {
          this.setState({
            message: res.data,
          });
          setTimeout(() => {
            this.setState({
              message: "",
              modal: false,
            });
            this.reset();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        });
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
    refresh();
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  // render for job apply page
  render() {
    const { name, email } = this.state.formErrors;
    const { fileError } = this.state;
    return (
      <div>
        <ul style={{ listStyle: "none" }}>
          <li>
            <div className="details-flex-main-div">
              <div className="details-flex-div-one"> Job Role</div>
              <div className="details-flex-div-two">:</div>
              <div className="details-flex-div-three">
                {this.props.job.jobRole}
              </div>
            </div>
          </li>
          <li>
            <div className="details-flex-main-div">
              <div className="details-flex-div-one"> Description</div>
              <div className="details-flex-div-two">:</div>
              <div className="details-flex-div-three">
                {this.props.job.jobDesc}
              </div>
            </div>
          </li>
          <li>
            <div className="details-flex-main-div">
              <div className="details-flex-div-one"> Domain </div>
              <div className="details-flex-div-two">:</div>
              <div className="details-flex-div-three">
                {this.props.job.domain}
              </div>
            </div>
          </li>
          <li>
            <div className="details-flex-main-div">
              <div className="details-flex-div-one"> Required skills</div>
              <div className="details-flex-div-two">:</div>
              <div className="details-flex-div-three">
                {this.props.job.skills}
              </div>
            </div>
          </li>
          <li>
            <div className="details-flex-main-div">
              <div className="details-flex-div-one">Location</div>
              <div className="details-flex-div-two">:</div>
              <div className="details-flex-div-three">
                {this.props.job.location}
              </div>
            </div>
          </li>
        </ul>
        <span
          className="btn p-2 login-button white-text"
          onClick={this.toggle}
          id="carrerbtn"
        >
          Apply Now
        </span>
        <Modal
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="apply-modal"
        >
          <ModalHeader toggle={this.toggle}>
            Fill the below form and apply
          </ModalHeader>
          <ModalBody>
            <Form
              className="p-3"
              onSubmit={this.onSubmit}
              encType="multipart/form-data"
              noValidate
            >
              <FormGroup className="row p-3">
                <Label className="col-md-2 bold">Name</Label>
                <Input
                  type="text"
                  id="apply-Name"
                  name="name"
                  className={`form-control-sm ${name && "is-invalid"}`}
                  onChange={this.onChange}
                  value={this.state.name}
                  onBlur={this.onBlur}
                  required={true}
                />
                {errors.name && (
                  <FormText className="careerError">{errors.name}</FormText>
                )}
              </FormGroup>

              <FormGroup className="row p-3">
                <Label className="col-md-2 bold">Email</Label>
                <Input
                  type="email"
                  name="email"
                  className={`form-control-sm ${email && "is-invalid"}`}
                  id="apply-Email"
                  onChange={this.onChange}
                  value={this.state.email}
                  onBlur={this.onBlur}
                  required={true}
                />
                {errors.email && (
                  <FormText className="careerError">{errors.email}</FormText>
                )}
              </FormGroup>
              <FormGroup className="row p-3">
                <Label className="col-md-2 bold">Phone</Label>
                <Input
                  type="tel"
                  name="phone"
                  id="apply-Phone"
                  value={this.state.phone}
                  onChange={this.onChange}
                  required={true}
                />
                {errors.phone && (
                  <FormText className="careerError">{errors.phone}</FormText>
                )}
              </FormGroup>
              <FormGroup className="row p-3">
                <Label className="col-md-2 bold">Resume</Label>
                <Input
                  disabled
                  type="text"
                  value={this.state.resume.name || ""}
                  className="col-md-7"
                  required={true}
                />
                <label className="btn file-button btn-file">
                  Browse
                  <Input
                    type="file"
                    name="resume"
                    style={{ display: "none" }}
                    className="col-md-2"
                    onChange={this.onFileChange}
                    required={true}
                  />
                </label>
                {fileError && (
                  <FormText className="error" style={{ paddingLeft: "130px" }}>
                    {fileError}
                  </FormText>
                )}
                {errors.resume && (
                  <FormText className="error" style={{ paddingLeft: "130px" }}>
                    {errors.resume}
                  </FormText>
                )}
              </FormGroup>
              <FormGroup className="center">
                <button
                  type="submit"
                  className="btn white-text login-button"
                  id="carrerbtn"
                >
                  Apply
                </button>
              </FormGroup>
              {this.state.message && <Notifier message={this.state.message} />}
              {this.state.error && <ErrorNotifier message={this.state.error} />}
            </Form>
          </ModalBody>
        </Modal>
        {/* {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />} */}
      </div>
    );
  }
}
export default CareerForm;
