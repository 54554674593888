import React from "react";
import { Card } from "reactstrap";

function HomeCard({
  icon,
  alt,
  heading,
  visibleData,
  data,
  href,
  serviceIcon,
  partnerIcon,
  productCard,
}) {
  return (
    <Card className={` ${productCard ? "product-home-card" : "home-card"}`}>
      {icon && (
        <div className={"home-card-icon-container center"}>
          <img
            src={icon}
            alt={alt}
            className={`${serviceIcon && "home-card-img-bg"} home-card-img ${
              partnerIcon && "home-card-img-no-radius"
            }`}
          />
        </div>
      )}
      <h5 className="dark-blue-text bold center home-card-head">{heading}</h5>{" "}
      <div className="home-card-span-div">
        <span className=" m-0">{visibleData}</span>
        <span className="home-card-text">{data}</span>
      </div>
      <div className="know-more-above-div"></div>
      {href && (
        <div className=" center home-card-know-more-container">
          <a
            href={href}
            rel="noopener noreferrer"
            target="_blank"
            className="blue-text differ-button"
          >
            Know More &gt;&gt;
          </a>
        </div>
      )}
    </Card>
  );
}

export default HomeCard;
