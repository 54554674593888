import React, { Component } from "react";
import { Row, Card } from "reactstrap";

import Newsticker from "./Newticker";
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selection: null,
      selected: null,
      searchTerm: "",
      message: "",
      error: "",
    };
  }

  /*
This function for the setting the value for the searchTerm
*/
  search = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  // Render for Job Search
  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const { selection } = this.state;
    return (
      <>
        {
          <Card
            className="container-card "
            style={{
              background: "#011631",
              height: "74vh",
              paddingBottom: "20px",
            }}
          >
            {!selection && (
              <h6
                className="bold mb-0 careers-card-heading "
                style={{ color: "white", padding: ".75rem 0 .75rem 40px" }}
              >
                Opportunities
              </h6>
            )}
            <i
              className="material-icons pionter careers-search-icon"
              style={{
                position: "absolute",
                top: "10px",
                right: "25px",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => this.props.setSearch(this.state.searchTerm)}
            >
              search
            </i>
            {!selection && (
              <form
                onSubmit={(e) => this.props.setSearch(this.state.searchTerm)}
              >
                <input
                  type="text"
                  onChange={this.search}
                  value={this.state.searchTerm}
                  className="searchfield form-control-sm"
                  style={{
                    width: "220px",
                    marginRight: "40px",
                    marginTop: "5px",
                  }}
                  placeholder="JobCode/JobRole/Skills"
                />
              </form>
            )}
            <Newsticker setSearch={this.props.setSearch} />
          </Card>
        }
      </>
    );
  }
}

export default Search;
