import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import icon1 from "../../../assets/img/service-icon1.png";
import icon2 from "../../../assets/img/service-icon2.png";
import icon3 from "../../../assets/img/service-icon3.png";
import icon4 from "../../../assets/img/service-icon4.png";
import icon5 from "../../../assets/img/service-icon5.png";
import icon6 from "../../../assets/img/service-icon6.png";
import HomeCard from "../../common/HomeCard";

export default () => {
  // initiating the aos animation
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);

  return (
    <div id="services" data-aos="fade-right">
      <div className="left section-header ">
        <h4 className="dark-blue-text bold service-header">Services</h4>
      </div>
      <Row className="m-0">
        <Col
          md="4"
          className="pl-0 p-bottom-col p-top-col services-first-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon1}
            alt="Consulting"
            heading="Consulting"
            visibleData="Comprehensive business and technology"
            data=" driven consulting services in the areas of AI, Cloud, Digital, DevOps, Enterprise Service Automation, and Quality Engineering."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="p-bottom-col p-top-col services-second-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon2}
            alt="Consulting"
            heading="Digital Solutions"
            visibleData="Next-gen web and mobile applications"
            data=" development, testing, implementations, and maintenance offerings with flexible consumption models by bringing innovation and agility."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="pr-0 p-top-col p-bottom-col services-third-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon3}
            alt="Consulting"
            heading="Product Implementations"
            visibleData="Meets your application infrastructure and"
            data=" business goals with an in-depth design review, architecture considerations, data strategies & product maintenance specific to your environment."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="pl-0 p-top-col p-bottom-col services-second-row-first-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon4}
            alt="Consulting"
            heading="Quality Engineering"
            visibleData="We provide QA services in the areas of"
            data=" software development, products implementation, RPA, applications migration, performance engineering, systems security and accessibility."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="p-top-col p-bottom-col services-second-row-second-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon5}
            alt="Consulting"
            heading="Intelligent Automation"
            visibleData="Enabling technology and business process"
            data=" to accelerate innovations from functional silos to an integrated solution. We provide end-to-end automation services in the areas of DevOps, Business Process Automation and Service Integrations."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="pr-0 p-top-col p-bottom-col services-second-row-third-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon6}
            alt="Consulting"
            heading="Professional Services"
            visibleData="For niche areas such as Enterprise Service"
            data=" Automation and Autonomous testing in terms of assessments, solutions and implementation."
            serviceIcon={true}
          />
        </Col>
      </Row>
    </div>
  );
};
