import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import CareerItem from "./CareerItem";
import Search from "./Search";
import DetailCard from "../../common/DetailCard";

class Careers extends Component {
  constructor() {
    super();
    this.state = {
      selection: null,
    };
    this.close = this.close.bind(this);
    this.setSearch = this.setSearch.bind(this);
  }

  close() {
    console.log(this.state.selection);
    this.setState({
      selection: null,
    });
  }

  setSearch(value) {
    this.setState({
      selection: value,
    });
  }

  // initiating the aos animation
  componentDidMount() {
    console.log(this.state.selection, "career componentdidmount");
    this.close();
    Aos.init({
      duration: 2000,
    });
  }

  // render for career opening page
  render() {
    return (
      <div id="careers" data-aos="fade-right">
        <div className="left section-header">
          <h4 className="dark-blue-text bold  ">Careers</h4>
        </div>
        <Row className="career-item-row m-0">
          {!this.state.selection ? (
            <Row className="m-0">
              <Col md="6" className="pl-0 careers-first-col">
                <Col md="12" className="pl-0" className="career-col-one">
                  <div className="p-top-col p-bottom-col" data-aos="fade-up">
                    <DetailCard
                      heading="Why Pion Global...?"
                      visibleData="At Pion, we endeavor to provide a comfortable platform for professionals who will be our front-line champions to redefine the limits of technology to make our customers successful in their vision."
                      data="Pion Global's smart recruitment system helps in hiring the right talents in a smarter way from the industry. If you believe you are an experienced person in the pursuit of excellence in challenging IT scenario, and looking for right opportunities to visualize your dreams, look no further. A career at Pion Global offers just that and a lot more."
                    />
                  </div>
                </Col>

                <Col md="12" className="pl-0 " className="career-col-two">
                  <div className="p-top-col " data-aos="fade-up">
                    <DetailCard
                      heading="Experience at Pion Global"
                      visibleData="Our culture is developed by people oriented success themes such as touching lives and making a difference to employees, passionate towards customer success and maintain integrity in what we do."
                      data="We believe that excellence is not a destination but a journey on continuous feedback and improvement and we keenly promote an open culture, respect, responsibility, encouraging feedback, and actively transforming the visions into the action that gives more confidence to our employees."
                    />
                  </div>
                </Col>
              </Col>
              <Col
                md="6"
                style={{ paddingTop: "15px" }}
                data-aos="fade-up"
                className="pr-0 careers-second-col"
              >
                <Search setSearch={this.setSearch} />
              </Col>
            </Row>
          ) : (
            <Col md="12" className="center" data-aos="fade-up" className="pr-0">
              <CareerItem domain={this.state.selection} back={this.close} />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
export default Careers;
