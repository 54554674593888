import React from "react";
import { Button } from "./Button";
import { NavLink } from "reactstrap";
import "../../assets/css/common/HeroSection.css";

function HeroSection(props) {
  return (
    <>
      <div
        className={
          props.item.lightBg
            ? "home__hero-section"
            : "home__hero-section darkBg"
        }
      >
        {/* <div> */}
        <div
          className="row home__hero-row"
          style={{
            display: "flex",
            flexDirection:
              props.item.imgStart === "start" ? "row-reverse" : "row",
          }}
        >
          <div className="col">
            <div className="home__hero-text-wrapper">
              <div className="top-line">{props.item.topLine}</div>
              <h1 className={props.item.lightText ? "heading" : "heading dark"}>
                {props.item.headline}
              </h1>
              <p
                className={
                  props.item.lightTextDesc
                    ? "home__hero-subtitle"
                    : "home__hero-subtitle dark"
                }
              >
                {props.item.description}
              </p>
              <NavLink href={props.item.to} className="home_hero_btn_link">
                <Button buttonSize="btn--large" buttonColor="blue">
                  {props.item.buttonLabel}
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="col-2">
            <div className="home__hero-img-wrapper">
              <img
                src={props.item.img}
                alt={props.item.alt}
                className="home__hero-img"
              />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default HeroSection;
